import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { List, FlexboxGrid, IconButton, Toggle } from 'rsuite';
import { ListHeader, MainDivider } from '../../../admin/helpers/Admin_StyledComponents';
import { SeverityAlert } from '../../../../../../appHelpers/appCommonSC';
import { DateTime } from 'luxon';
import { UserContext } from '../../../_context/UserContext';
import { EditAlertFC } from '../EditAlertFC';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TrashIcon from '@rsuite/icons/Trash';
import RemindFillIcon from '@rsuite/icons/RemindFill';

export const AlertSetFC = ({ metadata, displayAlertConfigs, dataConfig, dataTable, handleDelete, handleUserEnabled, refresh }) => {
   const { id } = useParams();
   const {userRoles} = useContext(UserContext);

   useEffect(() => {
      
   }, [displayAlertConfigs]);

   return (
      <AlertSetSC>
         <HeaderText>Your Currently Set Alerts</HeaderText>

         <MainDivider/>

         <ListHeader>
            <FlexboxGrid>
               <FlexboxGrid.Item colspan={2}  align='center'>
                  SEVERITY
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={8} style={{paddingLeft: 10}}>
                  ALERT
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={3}  align='center'>
                  CURRENT STATUS
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={5}  align='center'>
                  MESSAGE
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={2}  align='center'>
                  FREQUENCY
               </FlexboxGrid.Item>
               <FlexboxGrid.Item colspan={2}  align='center'>
                  ENABLED
               </FlexboxGrid.Item>   
               <FlexboxGrid.Item colspan={2}  align='center'/>            
            </FlexboxGrid>
         </ListHeader>
         
         <List size="sm">
            {displayAlertConfigs && displayAlertConfigs?.length > 0 ?
               displayAlertConfigs.map((config, index) => (
                  config.user_id !== 'default' ?
                  <List.Item key={config.id +'_set'} value={config}  style={displayAlertConfigs.find(conf => (conf.user_id === 'default' && conf.id === config.default_id))?.enabled ? {backgroundColor: ''} : {backgroundColor: '#e5e5ea'}}>
                     {!displayAlertConfigs.find(conf => (conf.user_id === 'default' && conf.id === config.default_id))?.enabled ?
                        <FlexboxGrid>
                           <FlexboxGrid.Item colspan={24} style={{paddingLeft: 10, paddingBottom: 10}}>
                              <RemindFillIcon/> This alert has been globally disabled!
                           </FlexboxGrid.Item>
                        </FlexboxGrid>
                      : null
                     }
                     <FlexboxGrid>
                           <FlexboxGrid.Item colspan={2}  align='center'>
                              <SeverityAlert severity={config?.severity}>{config?.severity}</SeverityAlert>
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={8} style={{paddingLeft: 10}}>

                              {config.field_name === 'comp_vib_status' ? 
                                 `Trigger: Compressor is OFF!`
                              : config.operator === 'absent_null_data' ?
                                 `Trigger: There has been ${config?.threshold} count(s) of ${config?.field_name_alias}.`
                              : (config?.operator === 'offline' || config?.operator === 'frozen_host') ?
                                 `Trigger: ${config?.field_name_alias} within past ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                              : `Trigger: ${config?.field_name_alias} is ${config.operator?.replace(/_/g, ' ')} ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                              }
                           </FlexboxGrid.Item>

                           <FlexboxGrid.Item colspan={3}  align='center'>
                              {dataTable ?
                                 metadata.he_tank_volume && (config.field_name === 'he_level_value' || config.field_name === 'he_level_1_value') ?
                                    config?.operator === 'greater_than' && ((parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)) > parseFloat(config?.threshold) ?
                                       <SeverityAlert severity={config?.severity}>{(parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                                    : config?.operator === 'less_than'  && ((parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2))  < parseFloat(config?.threshold)  ?
                                       <SeverityAlert severity={config?.severity}>{(parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                                    : config?.operator === 'delta_neg' ?
                                       null    
                                    : 
                                    <SeverityAlert>{(parseFloat(dataTable[config?.field_name]) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                                 : config?.operator === 'greater_than' && parseFloat(dataTable[config?.field_name]) > parseFloat(config?.threshold) ?
                                    <SeverityAlert severity={config?.severity}>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                                 : config?.operator === 'less_than'  && parseFloat(dataTable[config?.field_name]) < parseFloat(config?.threshold)  ?
                                    <SeverityAlert severity={config?.severity}>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                                 : config?.operator === 'equals' && config.field_name === 'comp_vib_status'  ?
                                    dataTable[config?.field_name] === 0 ? <SeverityAlert severity={config?.severity}>OFF</SeverityAlert> : <SeverityAlert>ON</SeverityAlert>
                                 : (config?.operator === 'offline' || config?.operator === 'frozen_host') && Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours) > parseFloat(config?.threshold) ?
                                    <SeverityAlert severity={config?.severity}>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>       
                                 : (config?.operator === 'offline' || config?.operator === 'frozen_host') ?
                                    <SeverityAlert>{Math.round(DateTime.now().diff(DateTime.fromISO(dataTable[config?.field_name]), 'hours').hours)} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>   
                                 : (config?.operator === 'delta_neg' || config?.operator === 'absent_null_data') ?
                                    null
                                 : <SeverityAlert>{dataTable[config?.field_name]} {config?.threshold_units ? config.threshold_units : ''}</SeverityAlert>
                              : null
                              }
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={5} align='center'>
                              {dataTable ?                          
                                 config?.operator === 'greater_than' &&  parseFloat(dataTable[config?.field_name]) > parseFloat(config?.threshold)  ?
                                    `${dataTable[config?.field_name]} ${config?.threshold_units} is greater than ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                                 : config?.operator === 'less_than' &&  parseFloat(dataTable[config?.field_name]) < parseFloat(config?.threshold)  ?
                                    `${dataTable[config?.field_name]} ${config?.threshold_units} is less than ${config?.threshold} ${config.threshold_units ? config.threshold_units : ''}`
                                 : null
                              : null
                              }
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2}  align='center'>
                              <b>{config?.notify_frequency ? 
                                 config?.notify_frequency
                                 : displayAlertConfigs?.find(def => def.id === config.default_id)?.notify_frequency 
                              }</b> hours
                           </FlexboxGrid.Item>
                           <FlexboxGrid.Item colspan={2}  align='center'>
                           {displayAlertConfigs.find(conf => (conf.user_id === 'default' && conf.id === config.default_id))?.enabled ?
                              <Toggle defaultChecked={config.enabled} onClick={() => {handleUserEnabled(config.id)}}/>
                              :
                              <Toggle defaultChecked={config.enabled} disabled/>
                           }       
                           </FlexboxGrid.Item>     
                           <FlexboxGrid.Item colspan={2} align='center'>
                              {dataTable ?
                                 <EditAlertFC config={config} dataConfig={dataConfig} dataTable={dataTable} sme={id} refresh={refresh}/>
                                 : null
                              }                           
                           </FlexboxGrid.Item>
                     </FlexboxGrid>
                  </List.Item>
                  : null
               ))
            : <div>No Alerts</div>
            }
         </List>    
      </AlertSetSC>
   );
};

const AlertSetSC = styled.div`
   width: 85vw;
`;

const SmallText = styled.div `
   display: inline-block;
   font-size: 0.85em;
   color: rgb(191, 191, 191);
   
   @media screen and (max-width: 769px) {
      font-size: 0.75em;
   }
`;

const HeaderText = styled.div `
   width: 100%;
   height: 36px;
   font-size: 1.3em;

   display: inline-block;

   @media screen and (max-width: 769px) {
      font-size: 0.75em;
   }
`;