import React, { useState, useEffect, useContext, useRef } from 'react';
//import { renderCount } from '../../../../appHelpers/appLoggers';
import styled from 'styled-components';
import { Nav, IconButton } from 'rsuite';
import SettingIcon from '@rsuite/icons/legacy/Setting';
import { AccessListFC } from './AccessListFC';
import { ProfileSettings } from './ProfileSettings';
import { AlertSettingsFC } from './AlertSettingsFC';
import axiosInstance from '../../../../../appHelpers/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';
import { RecentActivity } from './RecentActivty';
import { useHistory, useParams } from 'react-router-dom';
import { SectionTitleFC } from '../../_helpers/SectionTitleFC';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import { UserAlerts } from './UserAlerts';
import { UserContext } from '../../_context/UserContext';
import { LoaderFC } from '../../_helpers/LoaderFC';
import { ReportSettingsFC } from './ReportSettingsFC';

export const _UserFC = (props) => {
   // renderCount(`AdminFC`);
   const {
      getUserProfile,
      userRoles
   } = useContext(UserContext);

   let history = useHistory();
   const { getAccessTokenSilently } = useAuth0();
   const [user, setUser] = useState();
   const [active, setActive] = useState('Profile');
   const [baseSettings, setBaseSettings] = useState([]);
   const [loadingUser, setLoadingUser] = useState([]);
   const [loadingBaseSettings, setLoadingBaseSettings] = useState([]);
   const { id } = useParams();

   const UserProfileNav = ({ active, onSelect, ...props }) => {
      return (
         <Nav {...props} vertical activeKey={active} onSelect={onSelect} style={{ width: '100%', marginTop: '5%'}}>
            <Nav.Item eventKey="Profile">Profile Settings</Nav.Item>
            <Nav.Item eventKey="Alerts">Alert Settings</Nav.Item>
            <Nav.Item eventKey="Reports">Report Settings</Nav.Item>
            {userRoles.includes('admin') || userRoles.includes('superuser') ?
               <>
                  <Nav.Item eventKey="Access">Access List</Nav.Item>
                  <Nav.Item eventKey="AlertHistory">Notification History</Nav.Item>         
                  <Nav.Item eventKey="Activity" disabled>Recent Activity</Nav.Item>
               </>
            : null
            }
         </Nav>
      );
   };

   // Retries User Profile Information Based on the User ID (param) that is given.
   const getUserProfileSettings = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const getUserProfile = await axiosInstance({
            url: `/user/profile/${id}`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setUser(getUserProfile?.data[0][0]);   
      } catch (error) {
         console.error(error);
      }
      setLoadingUser(false);
   };

   // Gets The Base System From the User Viewing the Profile (Usually Admin)
   const getBaseSettings = async () => {
      try {
         const token = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_EXPRESS_API}` });

         const baseSystemsRes = await axiosInstance({
            url: `/admin/base/machines`,
            data: ``,
            headers: { authorization: `Bearer ${token}` },
         });

         setBaseSettings(baseSystemsRes?.data[0]);
      } catch (error) {
         console.error(error);
      }
      setLoadingBaseSettings(false);
   };
   
   useEffect(() => {
      getUserProfile();
   }, [props]);

   useEffect(() => {
      getUserProfileSettings();
      getBaseSettings();
   }, [id]);

   return (
      <>
         <SectionTitleFC title={'USER PROFILE'} />

         {loadingUser || loadingBaseSettings ?
            <LoaderFC/>
         
            // Desktop View
            : window.screen.width >= 768 ?
               <_UserSC>
                  <BackButton>
                     <IconButton icon={<ArowBackIcon/>} onClick={() => history.goBack()}>Back</IconButton>
                  </BackButton>

                  <NavigationPane>
                     <UserPane>
                        <Avatar>
                           {(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}
                        </Avatar>
                        <UserName>
                           {(user.first_name ? user.first_name : "") + " " + (user.last_name ? user.last_name : "")}
                        </UserName>
                        
                        <ItemSubText>
                           {Object.values(user?.roles['roles'].join(', '))}
                        </ItemSubText>                
                     </UserPane>

                     <DivisionBar/>

                     <Header>
                        <SettingIcon/>Settings
                     </Header>

                     <UserProfileNav appearance="subtle" reversed active={active} onSelect={setActive} />
                  </NavigationPane>

                  <SettingPane>
                     {active === 'Profile' ?
                        <ProfileSettings user={user} roles={userRoles}/>
                     : active ==='Access' ?
                        <AccessListFC baseSystems={baseSettings} user={user.email_address}/>
                     : active ==='AlertHistory' ?
                        <UserAlerts user={user.email_address} />
                     : active ==='Alerts' ?
                        <AlertSettingsFC baseSystems={baseSettings} user={user} />
                     : active ==='Reports' ?
                        <ReportSettingsFC />
                     : active ==='Activity' ?
                        <RecentActivity/>
                     : null
                     }
                  </SettingPane>           
               </_UserSC>         
            : // Mobile View
               <_UserSC>
                  <UserPane>
                     <Avatar>
                        {(user.first_name ? user.first_name.charAt(0) : "") + " " + (user.last_name ? user.last_name.charAt(0) : "")}
                     </Avatar>
                     <UserName>
                        {(user.first_name ? user.first_name : "") + " " + (user.last_name ? user.last_name : "")}
                     </UserName>
                     
                     <ItemSubText>
                        {Object.values(user?.roles['roles'].join(', '))}
                     </ItemSubText>                
                  </UserPane>

                  <DivisionBar/>

                  <SettingPane>
                     {active === 'Profile' ?
                        <ProfileSettings user={user} roles={userRoles}/>
                     : active ==='Access' ?
                        <AccessListFC baseSystems={baseSettings} user={user.email_address}/>
                     : active ==='Alerts' ?
                        <UserAlerts user={user.email_address} />
                     : active ==='Activity' ?
                        <RecentActivity/>
                     :
                        <></>
                     }
                  </SettingPane>
               </_UserSC>
         }
      </>
   );
};

const _UserSC = styled.div`
   width: 100%;

   color: ${({ theme, darkMode }) =>
      darkMode ? theme.dark_accent : theme.light_accent};

`;

const Header = styled.div`
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   padding-left: 2.5%;
   padding-top: 2.5%;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }
`;

const NavigationPane = styled.div`
   float: left;
   width: 20%;
   padding-left: 2.5%;
   margin-top: 1%;
`;

const SettingPane = styled.div`
    float: right;
    width: 80%;
    margin-top: 5%;

    @media screen and (max-width: 769px) {
      float: left;
      margin-left: 10%;
      align-items: center;
   }
`;

const UserPane = styled.div`
   height: auto;

   @media screen and (max-width: 769px) {
      margin-top: 1%;
   }
`;

const UserName = styled.div`
   font-family: inherit;
   font-size: 1.5vw;
   font-weight: 400;
   padding-top: 5%;
   text-align: center;

   .rs-icon {
      margin-right: 2.5%;
      vertical-align: text-bottom;
   }

   @media screen and (max-width: 769px) {
      font-size: 7vw;
   }
`;

const Avatar = styled.div`
   width: 75px;
   height: 75px;
   border-radius: 50%;
   font-size: 35px;
   font-weight: bolder;
   font-family: roboto, arial;
   color: white;
   line-height: 75px;
   text-align: center;
   background: ${({ theme }) => theme.avante_dark_blue};
   margin: auto;
`;

const DivisionBar = styled.div`
   border-top: 1px solid #d9d9d9;
   margin-top: 2.5%;
   padding-bottom: 2.5%;
`;

const ItemSubText = styled.div`
   font-size: 0.9em;
   color: #97969B;
   font-weight: normal;
   text-align: center;

   @media screen and (max-width: 769px) {
      font-size: 0.9em;
      font-weight: normal;
   }
`;

const BackButton = styled.div`
   position: absolute;
   left: 0;
   padding-left: 5%;
   padding-top: 1.5%;
   margin-right: auto;
`